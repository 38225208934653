@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
.post-image {
  width: 50%;
}

@media (max-width: 767px) {
  .fuel-pricing-scroll-notification {
    display: block;
  }
}

@media (min-width: 768px) {
  .fuel-pricing-scroll-notification {
    display: none;
  }
}
